<template>
  <div v-if="spinner.visible" class="loading-page">
    <div class="loading" />
    <div v-if="showText" class="loading-text" v-html="text" />
  </div>
</template>

<script setup>
import { spinnerStore } from '@/stores/spinner'
import { ref, onBeforeMount } from 'vue'

const spinner = spinnerStore()
const showText = ref(false)
const text = ref('')

onBeforeMount(() => {
  const currentUrl = window.location.pathname
  if (currentUrl.includes('/marketing')) {
    text.value = 'Creazione del report in corso...<p>L\'operazione potrebbe richiedere qualche secondo, attendi perfavore</p>'
  }
})

</script>

<style lang="scss" scoped>
.loading-text {
  font-size: 16px;
  color: whitesmoke;

  p {
    font-size: 14px;
  }
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    border: 15px solid rgba(255, 255, 255, 0.705);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
