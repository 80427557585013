<template>
  <div class="floating-button-container" :class="{'open':openMenu}">
    <div class="floating-button-tooltip">
      {{ tooltip }}
    </div>
    <div class="floating-button-popup popup-ani" :style="openMenu? 'height:'+ calcHeight +'px':'height:5px'">
      <ul>
        <li v-for="(l,index) in list" :key="index" @click.stop.prevent="doAction(l.payload)">
          {{ l.name }}
        </li>
      </ul>
    </div>
    <div class="floating-button" @click.stop.prevent="open">
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
export default {
  props: {
    menu: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: ()=>{[]}
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  emits: ['action'],
  setup(props, { emit }) {
    const state = reactive({
      openMenu: false
    })
    const open = () =>{
      if(props.menu){
        state.openMenu = !state.openMenu
      } else {
        emit('action')
      }
    }
    const close = () =>{
      state.openMenu = false
    }
    const doAction = (e) =>{
      emit('action', e)
      state.openMenu =false
    }
    const calcHeight = computed(() => {
      return (props.list.length * 35) 
    })
    return {
      ...toRefs(state),
      open,
      doAction,
      calcHeight,
      close
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../assets/scss/floatingButton';

</style>