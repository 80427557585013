<template>
  <button
    v-if="submit"
    submit
    class="pure-material-button-contained"
    :class="[type, {'small': small}]"
    :disabled="disabled"
    @click="handleClick($event)"
    v-html="text"
  />
  <button
    v-else
    class="pure-material-button-contained"
    :class="[type, {'small': small}]"
    :disabled="disabled"
    @click="handleClick($event)"
    v-html="text"
  />
</template>

<script>
export default {
  props: {
    text: String,
    disabled: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: ()=>{return false}
    },
    type: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['click'],
  setup(props, { emit }) {
    const handleClick = (event) => emit('click', event)

    return {
      props,
      handleClick
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/colors.scss';
button.disabled.info.pure-material-button-contained{
background-color: #9c9e9f45;
}

.pure-material-button-contained {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	border: none;
	border-radius: 4px;
	padding: 0 16px;
	min-width: 64px;
	height: 36px;
	vertical-align: middle;
	text-align: center;
	text-overflow: ellipsis;
	text-transform: uppercase;
	color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
	background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	font-family: var(--pure-material-font, 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system);
	font-size: 14px;
	font-weight: 500;
	line-height: 36px;
	overflow: hidden;
	outline: none;
	cursor: pointer;
	transition: box-shadow 0.2s;
	&.small {
		transform: scale(.85);
		white-space: nowrap;
	}

	&:disabled {
		color: white;
		background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
		box-shadow: none;
		cursor: initial;
		&::before {
			opacity: 0;
		}
		&::after {
			opacity: 0;
		}
	}

	&.danger {
		background-color: var(--danger);

		&:disabled {
			background-color: #dc35456e;
		}
	}

	&.success {
		background-color: var(--success);

		&:disabled {
			background-color: #28a7456e;
		}
	}

	&.warning {
		color:white;
		background-color: var(--warning);

		&:disabled {
			color:white;
			background-color: #ffc1076e;
		}
	}

	&.info {
		background-color: var(--info);
		.disabled,
		&:disabled {
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
			background-color: rgba(156, 158, 159, 0.25);
			color: rgba(245, 245, 245, 0.65);
		}
	}
	&.error {
		background-color: var(--danger);

		&:disabled {
			background-color: #dc354545;
		}
	}

	&.light {
		background-color: var(--light);
		color: black;

		&:disabled {
			background-color: #17a2b86e;
		}
	}

	&::-moz-focus-inner {
		border: none;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
		opacity: 0;
		transition: opacity 0.2s;
	}
	&::after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		border-radius: 50%;
		padding: 50%;
		width: 32px;
		height: 32px;
		background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
		opacity: 0;
		transform: translate(-50%, -50%) scale(1);
		transition: opacity 1s, transform 0.5s;
	}
	&:hover {
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		&::before {
			opacity: 0.08;
		}
		&:focus {
			&::before {
				opacity: 0.3;
			}
		}
	}
	&:focus {
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		&::before {
			opacity: 0.24;
		}
	}
	&:active {
		box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
		&::after {
			opacity: 0.32;
			transform: translate(-50%, -50%) scale(0);
			transition: transform 0s;
		}
	}
}
</style>
