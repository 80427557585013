<template>
  <header v-if="navbar.user" class="header">
    <div class="container">
      <font-awesome-icon icon="bars" class="fa-xl sidebar-toggler" @click="toggleSidebar()" />
      <h2>{{ navbar.title }}</h2>
      <div v-if="navbar.user" class="right" @click="logout()">
        <div class="header-font">
          {{ getName }} 
        </div>
        <font-awesome-icon icon="arrow-right-from-bracket" class="header-font" />
      </div>
    </div>
  </header>
</template>

<script setup>
import router from './../router'
import { sidebarStore } from '@/stores/sidebar'
import { navbarStore } from '@/stores/navbar'
import { computed } from 'vue'

const sidebar = sidebarStore()
const navbar = navbarStore()

const logout = () => {
  navbar.user = null
  localStorage.removeItem('userId')
  localStorage.removeItem('user')
  localStorage.removeItem('roles')
  localStorage.removeItem('privileges')

  router.push({ path: '/login' })
      
  document.getElementById('mySidebar').style.width = '0'
  document.getElementById('main').style.marginLeft = '0'
}

const toggleSidebar = () => sidebar.toggle()

const getName = computed(() => navbar.user)

</script>

<style lang="scss" scoped>
@use '../assets/scss/navbar';
</style>
