import axios from 'axios'
import router from './router'

function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const onFullfilled = (response) => response

const onRejected = (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem('userId')
    localStorage.removeItem('user')
    router.push({ path: '/login' })
    document.getElementById('mySidebar').style.width = '0'
    document.getElementById('main').style.marginLeft = '0'
  } else if (error?.response?.status == 406) {
    if (!error?.response?.data?.messageCode) {
      router.push({ path: '/unauthorized' })
    }
  }
  return Promise.reject(error)
}

export const api = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API
})

api.interceptors.request.use(request => {
  // add auth header with jwt if account is logged in and request is to the api url
  request.headers['API-X-XSRF-TOKEN'] = getCookie('API-XSRF-TOKEN')
  return request
})

export const sbapi = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_SBAPI
})

export const sbapibackoffice = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_SBAPIBACKOFFICE
})
export const reportbackoffice = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_REPORT
})
export const apicross = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_APICROSS
})

api.interceptors.response.use(onFullfilled, onRejected)
sbapi.interceptors.response.use(onFullfilled, onRejected)
sbapibackoffice.interceptors.response.use(onFullfilled, onRejected)
reportbackoffice.interceptors.response.use(onFullfilled, onRejected)
apicross.interceptors.response.use(onFullfilled, onRejected)
