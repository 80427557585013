import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'

export const sidebarStore = defineStore('sidebar', () => {
  const opened = ref(false)

  function open() {
    opened.value = true
    localStorage.setItem('sidebarOpened', opened.value)
    document.getElementById('mySidebar').style.width = '250px'
    if (window.innerWidth > 500) document.getElementById('main').style.marginLeft = '250px'
  }

  function close() {
    opened.value = false
    console.log('close sidebar')
    localStorage.setItem('sidebarOpened', opened.value)
    if (document.getElementById('mySidebar')) {
      if (window.innerWidth > 500) {
        document.getElementById('mySidebar').style.width = '74px'
        document.getElementById('main').style.marginLeft = '74px'
      } else {
        document.getElementById('mySidebar').style.width = '0'
        document.getElementById('main').style.marginLeft = '0'
      }
    }
  }

  function toggle() {
    opened.value = !opened.value
    localStorage.setItem('sidebarOpened', opened.value)
    opened.value ? open() : close()
  }

  function hide() {
    opened.value = false
    localStorage.setItem('sidebarOpened', opened.value)
    console.log('hide')
    document.getElementById('mySidebar').style.width = '0'
    document.getElementById('main').style.marginLeft = '0'
  }

  onMounted(() => {
    if (localStorage.getItem('userId')) {
      opened.value = localStorage.getItem('sidebarOpened') === 'true'
      opened.value ? open() : close()
    } else {
      // user not logger
      if (document.getElementById('mySidebar'))
        document.getElementById('mySidebar').style.width = '0'
      if (document.getElementById('main'))
        document.getElementById('main').style.marginLeft = '0'
    }
  })

  return { opened, open, close, toggle, hide }
})
