import { defineStore } from 'pinia'
import { onMounted, ref } from 'vue'

export const navbarStore = defineStore('navbar', () => {
  const user = ref('')
  const title = ref('')

  onMounted(() => {
    user.value = localStorage.getItem('user')
  })

  return { user, title }
})
