import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'

export const permissionsStore = defineStore('permissions', () => {
  const roles = ref([])
  const privileges = ref([])

  const clear = () => {
    roles.value = []
    privileges.value = []
    localStorage.removeItem('roles')
    localStorage.removeItem('privileges')
  }

  const canUse = (privilege) => {
    // if (!privileges.value.length) refresh()
    
    if (!privilege) return true

    return privileges.value.includes(privilege) || roles.value.includes('ADMIN')
  }

  const refresh = () => {
    if (localStorage.getItem('roles')) {
      roles.value = JSON.parse(localStorage.getItem('roles'))
    }
    if (localStorage.getItem('privileges')) {
      privileges.value = JSON.parse(localStorage.getItem('privileges'))
    }
  }
  
  onMounted(() => {
    refresh()
  })

  return { roles, privileges, clear, canUse, refresh }
})
