import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import LoadScript from 'vue-plugin-load-script'
import vSelect from 'vue-select'

const app = createApp(App)
app.directive('safe-html', {
  mounted(el, binding) {
    el.innerHTML = binding.value
  }
})

app.use(LoadScript)
// #region font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRightFromBracket,
  faTrashCan,
  faMagnifyingGlass,
  faLock,
  faEnvelope,
  faArrowDownAZ,
  faArrowDownZA,
  faCheck,
  faEllipsisVertical,
  faBars,
  faListCheck,
  faCheckToSlot,
  faBuilding,
  faUsers,
  faUserSecret,
  faCircleDollarToSlot,
  faUpload,
  faAngleRight,
  faAngleDown,
  faMoneyBillWave,
  faArrowDown19,
  faArrowDown91,
  faCreditCard,
  faMoneyBills,
  faBullhorn,
  faFileCsv,
  faInfo,
  faPlus,
  faXmark,
  faTag,
  faTriangleExclamation,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowRightFromBracket,
  faTrashCan,
  faMagnifyingGlass,
  faLock,
  faEnvelope,
  faArrowDownAZ,
  faArrowDownZA,
  faCheck,
  faEllipsisVertical,
  faBars,
  faListCheck,
  faCheckToSlot,
  faBuilding,
  faUsers,
  faUserSecret,
  faCircleDollarToSlot,
  faUpload,
  faAngleRight,
  faAngleDown,
  faMoneyBillWave,
  faArrowDown19,
  faArrowDown91,
  faCreditCard,
  faMoneyBills,
  faBullhorn,
  faFileCsv,
  faInfo,
  faPlus,
  faTag,
  faXmark,
  faTriangleExclamation,
  faChevronUp,
  faChevronDown,
  faXmark
)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'vue-select/dist/vue-select.css'
app.component('VSelect', vSelect)

app.component('FontAwesomeIcon', FontAwesomeIcon)
// #endregion

import Spinner from './components/Spinner'
app.component('Spinner', Spinner)

import MaterialInput from './components/MaterialInput'
app.component('MaterialInput', MaterialInput)

import MaterialButton from './components/MaterialButton'
app.component('MaterialButton', MaterialButton)

import MaterialDatePicker from './components/MaterialDatePicker'
app.component('MaterialDatePicker', MaterialDatePicker)

import FloatingButton from './components/FloatingButton'
app.component('FloatingButton', FloatingButton)

// https://vuejsexamples.com/vue-js-toast-notification-plugin-for-vue-3/
import Toaster from '@meforma/vue-toaster'
app.use(Toaster, {
  position: 'top-right'
})
app.provide('$toast', app.$toast)

import { createPinia } from 'pinia'
app.use(createPinia())

console.log('modalità: ', process.env.NODE_ENV)
// Imposta Vue in modalità di produzione per disabilitare i console.log di Vue stessa
if (process.env.NODE_ENV === 'production') {
  console.log = function () { }
}
app.use(router)
app.mount('#app')